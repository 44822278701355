<template>
  <div class="relative flex items-center bg-white rounded">
    <input
      class="flex-grow rounded py-3 pl-4 bg-white outline-none default-animation"
      :value="url"
      @focus="$event.target.select()"
    />
    <div class="flex items-center flex-none  relative">
      <transition
        enter-active-class="miniFadeInRight"
        leave-active-class="miniFadeOutRight"
        mode="out-in"
      >
        <div
          class="  flex-none flex absolute right-0 z-0 py-2 px-3 bg-white text-blue-700 w-48 mr-16 text-center justify-center"
          v-if="copySuccess"
        >
          Copied to clipboard
        </div>
      </transition>
      <button
        class=" p-1 px-2 bg-blue-400 mr-2 rounded z-10 text-white"
        v-clipboard:copy="url"
        v-clipboard:success="handleCopy"
        v-clipboard:error="handleError"
      >
        Copy
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['url'],
  data() {
    return {
      copySuccess: null
    };
  },
  methods: {
    handleCopy: function() {
      this.$store.dispatch('sendMpEvent', {
        event: 'Referral Link Copied',
        properties: {
          location: this.$route.name,
          referralLink: this.url,
          type: 'EVT'
        }
      });
      this.copySuccess = true;
      setTimeout(
        function() {
          this.copySuccess = false;
        }.bind(this),
        2000
      );
    },
    handleError: function(e) {
      console.log(e);
    }
  }
};
</script>

<style lang="css" scoped></style>
