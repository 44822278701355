<template>
  <div class="py-6">
    <transition
      enter-active-class="miniFadeInUp"
      leave-active-class="miniFadeOutDown"
      mode="out-in"
    >
      <div v-if="!submissionSuccess" class="space-y-6" key="feedback-form">
        <div class="text-lg font-semibold">
          What do you plan on feeding next?
        </div>

        <div>
          <textarea
            name="review-content"
            class="full-width"
            v-model="feedback"
            placeholder="Please let us know the name or type of food..."
          ></textarea>
        </div>

        <div class="flex sm:justify-center">
          <form
            @submit.prevent="sendFeedback"
            novalidate
            class="w-full sm:w-64"
          >
            <loading-button
              :is-loading="isLoading"
              :is-enabled="true"
              label="Let us know, Thank you!"
              is-full
            />
          </form>
        </div>
      </div>
      <div
        v-else
        key="feedback-success"
        class="space-y-6 bg-blue-100 rounded-lg py-4"
      >
        <div class="text-center font-semibold">Thanks for letting us know!</div>
      </div>
    </transition>
  </div>
</template>

<script>
import LoadingButton from '@/components/LoadingButton.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'CancelWherenext',
  components: { LoadingButton },
  data() {
    return {
      feedback: '',

      isLoading: false,
      submissionSuccess: false
    };
  },
  props: {
    onTrial: {
      type: Boolean,
      default: false
    },
    reason: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      isLoggedIn: 'isLoggedIn'
    })
  },
  methods: {
    ...mapActions({
      sendToNotion: 'sendToNotion'
    }),
    sendFeedback() {
      this.isLoading = true;
      const data = {
        Name: {
          title: [
            {
              text: {
                content: this.currentUser ? this.currentUser.name : '-'
              }
            }
          ]
        },
        Trial: {
          rich_text: [
            {
              type: 'text',
              text: {
                content: this.onTrial ? 'Yes' : 'No'
              }
            }
          ]
        },
        Reason: {
          rich_text: [
            {
              type: 'text',
              text: {
                content: this.reason
              }
            }
          ]
        },
        Userid: {
          number: Number(this.currentUser ? this.currentUser.id : '-')
        },
        NextFood: {
          rich_text: [
            {
              type: 'text',
              text: {
                content: this.feedback
              }
            }
          ]
        },
        Email: {
          rich_text: [
            {
              type: 'text',
              text: {
                content: this.currentUser.email
              }
            }
          ]
        }
      };
      this.sendToNotion({
        db: 'a3717fff57164651a3883601727c174c',
        data
      })
        .then(response => {
          this.isLoading = false;
          this.submissionSuccess = true;
          console.log(response);
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
          this.submissionSuccess = true;
        });
    }
  },
  mounted() {
    if (this.$route.query.score) {
      this.stars = Number(this.$route.query.score);
    }

    if (this.isLoggedIn) {
      this.emailAddress = this.currentUser.email;
    }
  }
};
</script>

<style></style>
