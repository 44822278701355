<template>
  <div class="container relative mx-auto flex-grow py-16 text-center">
    <div class="relative z-10 mx-auto">
      <div class="z-10 mb-8 px-8 font-merri text-xl">
        Success. Your order is being prepared 🎉
      </div>
      <div class="z-10 mb-4 px-8">
        You can expect it to be with you within the next 1 - 3 working days.
      </div>

      <div class="mb-12 px-8">
        Thank you for the opportunity, Welcome to The Cool Cat Club.
      </div>
      <div class="mx-auto w-full sm:w-2/3">
        <referral />
      </div>
    </div>

    <router-link
      class="mt-8 block text-blue-400 underline"
      :to="{ name: 'dashboard' }"
      >Go to your dashboard
      <font-awesome-icon :icon="['far', 'arrow-right-long']" class="ml-2"
    /></router-link>
  </div>
</template>

<script>
import store from "@/store";
import utils from "@/utils";

import Referral from "@/components/Referral";
import { mapGetters } from "vuex";
import _ from "lodash";
import { last } from "lodash/fp";
export default {
  components: {
    Referral,
  },
  created() {
    localStorage.removeItem("signup");
    localStorage.removeItem("isTrial");
    this.$store.state.core.isSignup = false;
  },
  data() {
    return {
      utils,
      referralUrl: this.$store.state.core.user?.referralUrl,
      sentConversion: localStorage.getItem("sC")
        ? localStorage.getItem("sC")
        : false,
      id: null,
    };
  },
  methods: {
    getSubItems: function (plans) {
      let self = this;
      let products = [];

      // Gets all the products
      _.forEach(plans.items, function (value) {
        let planId = self.getPlanId(value);
        products.push(planId);
      });
      // Filters out so only gets uniques & works out quantities
      let uniqProducts = _.uniq(products);
      let productCounts = [];
      _.forEach(uniqProducts, function (value) {
        productCounts[value] = _.filter(products, function (o) {
          return o === value;
        }).length;
      });
      // Adds each product as an item
      let items = [];
      _.forEach(plans.items, function (value) {
        if (!_.find(items, ["id", self.getPlanId(value)])) {
          items.push({
            id: self.getPlanId(value),
            name: self.getPlanName(value),
            price: value.displayCost,
            quantity: productCounts[self.getPlanId(value)],
          });
        }
      });

      return items;
    },
    getPlanId: function (value) {
      return value.pouches + "_" + value.dryfood;
    },
    getPlanName: function (value) {
      return (
        value.pouches + " pouches, " + value.dryfood / 1000 + "kg dry food"
      );
    },
    getSku(sku) {
      return utils.skuToProduct(sku);
    },
    getProductPrice(sku) {
      const product = this.getSku(sku);
      return Number(product.exvat / 100).toFixed(2);
    },
    getSubscriptionItems(cats) {
      const items = [];

      cats.forEach((c) => {
        c.products.forEach((p) => {
          items.push({
            id: p.sku,
            name: p.sku,
            price: this.getProductPrice(p.sku),
            quantity: p.quantity,
          });
        });
      });
      return items;
    },
  },
  computed: {
    ...mapGetters({
      samplePrice: "getSamplePrice",
      isTaster: "getIsTaster",
      user: "getCurrentUser",
    }),
  },
  mounted() {
    let self = this;

    /// set timeout for 1 second to allow for the order to be created
    setTimeout(() => {
      this.$store.dispatch("getOrderHistory").then((response) => {
        this.id = response.data.content[0]?.invId || this.user?.id;

        this.$store.dispatch("sendMpEvent", {
          event: `${this.isTaster ? "Taster Box" : "Subscription"} success`,
          properties: {
            location: this.$route.name,
            adid: JSON.stringify(utils.getStorage("adid", "local")),
            adidLatest: last(utils.getStorage("adid", "local")),
            type: "EVT",
            orderId: this.id,
          },
        });
        const gtag = window.gtag;

        if (gtag && this.isTaster) {
          this.$store.dispatch("sendMpEvent", {
            event: "gtag_purchase",
            properties: {
              transaction_id: this.id.toString(),
              value: Number(localStorage.getItem("tasterTotalAmount")),
              currency: "GBP",
            },
          });

          //set up a GA4 purchase event
          gtag("event", "purchase", {
            transaction_id: this.id.toString(),
            value: Number(localStorage.getItem("tasterTotalAmount")),
            currency: "GBP",
            items: [
              {
                item_id: "taster_box",
                item_name: "Taster Box",
                price: 5,
                quantity: Number(localStorage.getItem("tasterTotalAmount")) / 5,
              },
            ],
          });

          if (!this.sentConversion) {
            this.sentConversion = true;
            localStorage.setItem("sC", true);
            localStorage.removeItem("tasterTotalAmount");
          }
        }
      });
      this.$store.dispatch("getSubscription");

      if (this.isTaster) {
        const date = new Date();

        this.$store.dispatch("sendFbEvent", {
          type: "track",
          name: "Purchase",
          eventId: `taster_${date.getDate()}${date.getMonth()}${date.getFullYear()}${
            this.user.id
          }`,
          data: {
            content_type: utils.getStorage("checkoutType", "local"),
            value: localStorage.getItem("tasterTotalAmount"),
            currency: "GBP",
          },
        });

        store.dispatch("getUser").then(function (response) {
          self.referralUrl = response?.referralUrl;
        });
        let totalCats = utils.getStorage("cats", "local");
        totalCats.forEach((pc) => {
          this.$store.dispatch("sendMpEvent", {
            event: "Cat Signup Amount",
            properties: {
              amount: pc.foodAmount,
              isKitten: pc.isKitten,
              type: "EVT",
            },
          });
        });
        localStorage.removeItem("checkout");
        localStorage.removeItem("cats");
        localStorage.removeItem("plans");
        localStorage.removeItem("signup");
        localStorage.removeItem("checkoutType");
      }
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
.bg-wrapper {
  transform: rotate(12deg);
}
.outline-fill {
  @apply fill-current;
}
</style>
