<template>
  <div
    class=" w-full flex justify-between text-center flex-col sm:flex-grow bg-blue-100 p-4   relative  rounded-lg border-2 border-blue-400  "
  >
    <div class="absolute right-0 top-0 mt-10 sm:mt-0 p-4 ">
      <font-awesome-icon
        class="text-blue-200 text-6xl"
        :icon="['far', 'coins']"
      />
    </div>
    <div class="relative z-10">
      <div class="text-lg text-center font-semibold mb-4">
        Earn
        <span class="sm:inline-block font-semibold border-b-2 border-blue-400 "
          >£10 credit</span
        >
        for every friend you refer!
      </div>
      <div class="mb-4 ">
        Recommend a cat friend or neighbour, when they subscribe get £10 credit
        towards your next box.
      </div>
      <div class="mb-4 ">
        Just share your unique link below!
      </div>
      <referral-link :url="user.referralUrl" />
    </div>
  </div>
</template>

<script>
import ReferralLink from '@/components/ReferralLink';
export default {
  components: {
    ReferralLink
  },
  computed: {
    user: function() {
      return this.$store.state.core.user;
    }
  }
};
</script>

<style lang="css" scoped></style>
