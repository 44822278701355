<template>
  <div class="w-full md:w-1/2 mx-auto text-center relative">
    <div
      class="text-xl xl:text-2xl z-20 font-semibold relative mb-6 px-2 text-center"
    >
      We've cancelled your subscription
    </div>
    <div v-if="passedMessage" class="space-y-4 mb-6">
      <div>We are extremely sorry to hear your cat has passed away.</div>
      <div>
        From all of us here at Cool Cat HQ, we send you our deepest sympathies
        and a warm hug.
      </div>
    </div>

    <div>Thank you for supporting a small business.</div>
    <div v-if="!passedMessage">
      <div>
        We're constantly adding to our range and it's simple to re-join at any
        time.
      </div>
      <div>
        <cancel-where-next :on-trial="isOnTrial" :reason="cancelReason" />
      </div>
    </div>
    <div class="p-4 mt-8 relative mb-0 border-t-2" v-if="user.shelterpouches">
      <p class="text-gray-600 relative z-20 mt-4">Did you know...</p>
      <div class="relative z-10">
        During your time with us you donated
        <span class="text-lg font-bold">
          {{ user.shelterpouches ? user.shelterpouches : '0' }}
        </span>
        pouches to less fortunate cats.
      </div>

      <p class="relative z-10 mt-8">
        They have thrived because of you. <br />Thank you from us and them.
      </p>
    </div>
    <div class="text-4xl">😻</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CancelWhereNext from './CancelWhereNext.vue';
export default {
  components: { CancelWhereNext },
  computed: {
    ...mapGetters({
      user: 'getCurrentUser'
    }),
    passedMessage: function () {
      return this.$route.params.passed === true;
    },
    isOnTrial: function () {
      return this.$route.params.isOnTrial;
    },
    cancelReason: function () {
      return this.$route.params.cancelReason;
    }
  },
  created() {
    this.$store.dispatch('getUser', true);
  }
};
</script>

<style lang="scss" scoped>
.rotator {
  transform: rotate(12deg);
}
.outline-fill {
  fill: currentColor;
}
</style>
